import {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import m from 'moment';

import {
	Alert,
	Box,
	CircularProgress,
	Grid,
	Paper,
	Snackbar,
	Tab,
	Tabs,
} from '@mui/material';

import useAppSettings from '../hooks/useAppSettings';
import useProject from '../hooks/useProject';

import apiGetProjectDetails from '../providers/api/get-project-details';
import apiSaveProject from '../providers/api/save-project';
import apiUpdateProject from '../providers/api/update-project';

import generateResults from '../providers/generate-results';

import Content from './ProjectDetail/Tabs/Content';
import Details from './ProjectDetail/Tabs/Details';
import Results from './ProjectDetail/Tabs/Results';
import ScenarioController from './ProjectDetail/ScenarioController';
import ScenarioDialog from './ProjectDetail/ScenarioDialog';
import Sidebar from './ProjectDetail/Sidebar';

import PageHeader from '../components/PageHeader';
import ConfirmDialog from '../components/ConfirmDialog';

const TabPanel = ({children, value, index, ...other}) => (
	<div
		role="tabpanel"
		hidden={value !== index}
		id={`simple-tabpanel-${index}`}
		aria-labelledby={`simple-tab-${index}`}
		{...other}>
		{value === index && <Box padding={4}>{children}</Box>}
	</div>
);

const ProjectDetail = () => {
	const updateTimeout = useRef(false);

	const confirmDialog = useRef();
	const scenarioDialog = useRef();

	const params = useParams();
	const settings = useAppSettings();

	// The update status.
	const [lastUpdate, setLastUpdate] = useState(Date.now());

	// The page status.
	const [activeTab, setActiveTab] = useState(0);
	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);
	const [error, setError] = useState(false);

	// The snackbar state.
	const [snackBar, setSnackBar] = useState({open: false});

	// The project controller object.
	const project = useProject();
	const results = !loading ? generateResults(settings, project.scenario) : null;

	// Load the project data.
	useEffect(() => {
		apiGetProjectDetails(params.id).then(p => {
			if (!p) return setError(true);

			project.reset(p.info, p.activeScenario);
			project.setScenariosList(p.scenariosList);
			project.setRiskFactorsList(p.riskFactorsList);

			setLoading(false);
		});
	}, []);

	// Automatically save the project.
	useEffect(async () => {
		if (!results || !project.data.get('id') || [
			project.data.validate(), project.scenario.validate(),
		].includes(false)) return;

		if (m().diff(m(lastUpdate), 'minutes') >= 10) {
			alert("You haven't updated this project in a while. Refresh this page before making any changes.");
			return;
		}

		// Update the project.
		clearTimeout(updateTimeout.current);
		updateTimeout.current = setTimeout(async () => {
			const success = await apiUpdateProject(project, results);

			if (!success) {
				alert('There was an error syncing this project. Check your connection and refresh this page.');
			} else {
				setLastUpdate(Date.now());
			}
		}, 250);
	}, [project.data.getData(), project.scenario.getData()]);

	// Reset the project data on changing the project type.
	// Set the project status when something changes.
	useEffect(() => {
		project.scenario.onUpdate('data.type', (type, prevType) => {
			// TODO: Implement better update.
			// TODO: Ask if the system should replace data.

			// Check if each property exists in the default project before applying it.

			// Update general information
			// [
			// 	'approvalTime',
			// 	'hourlyRate',
			// 	'managementTime',
			// 	'notes',
			// 	'productionTeam.juniorDesigner',
			// 	'productionTeam.seniorDesigner',
			// 	'productionTeam.juniorDeveloper',
			// 	'productionTeam.seniorDeveloper',
			// 	'productionTeam.uxStrategist',
			//
			// ].map(key => {
			// 	const d = getFromDotNotation(settings.defaultProject[type], key);
			// 	if (!d) return;
			//
			// 	newData[key] = d;
			// });
			//
			// console.log(settings.defaultProject[type]);
			//
			// const newData = Object.entries(settings.defaultProject[type]).reduce(
			// 	(d, [key, value]) => ({...d, [key]: value}),
			// 	project.scenario.get('data')
			// );
			//
			// project.scenario.update('data', newData);
		});
	}, []);

	// Saves the current project in the backend.
	// TODO: Replace this function with handleCreate().
	const handleSave = async () => {
		setSaving(() => true);

		if ([project.data.validate(), project.scenario.validate()].includes(false)) {
			setSaving(() => false);
			setSnackBar(() => ({open: true, type: 'error', message: 'Check your project before saving.'}));
			return;
		}

		const saveResponse = await apiSaveProject({
			...project.data.getData(),
			scenarios: project.getScenarios(),
		});

		setSaving(() => false);

		if (saveResponse.status === 'SUCCESS') {
			project.data.update('id', saveResponse.data.id, false);
			[project.data, project.scenario].map(c => c.status('saved'));
		}

		setSnackBar(() => ({
			open: true,
			type: saveResponse.status.toLowerCase(),
			message: saveResponse.status === 'SUCCESS' ? 'Project saved.' : 'There was an unknown error while saving your project.',
		}));
	};

	/**
	 * Attempt to change the current scenario.
	 *
	 * @param {string|int} id
	 */
	const handleScenarioChange = async id => {
		if (id === '__new') {
			scenarioDialog.current.open(false, project.createScenario);
		} else {
			await project.changeScenario(id);
		}
	};

	// Closes the snackbar.
	const closeSnackbar = () => setSnackBar(prev => ({...prev, open: false}));

	const [riskfactor, setRiskfactor] = useState(1);

	return loading ? (
		<CircularProgress/>
	) : error ? (
		<Alert severity="error" variant="filled">There was an error loading the project details.</Alert>
	) : (
		<>
			<PageHeader
				title={project.data.get('title')}
				CustomRight={(
					<ScenarioController
						project={project}
						update={handleScenarioChange}
					/>
				)}
			/>

			<Box>
				<Grid container spacing={2}>
					<Grid item xs={9}>
						<Box component={Paper}>
							<Tabs
								value={activeTab}
								onChange={(e, tab) => setActiveTab(tab)}
								variant="fullWidth">
								<Tab label="Details" id="simple-tab-0" aria-controls="simple-tabpanel-0"/>
								<Tab label="Scope" id="simple-tab-1" aria-controls="simple-tabpanel-1"/>
								<Tab label="Results" id="simple-tab-2" aria-controls="simple-tabpanel-2"/>
							</Tabs>

							<TabPanel value={activeTab} index={0}>
								<Details project={project}/>
							</TabPanel>

							<TabPanel value={activeTab} index={1}>
								<Content project={project}/>
							</TabPanel>

							<TabPanel value={activeTab} index={2}>
								<Results results={results} riskfactor={riskfactor}/>
							</TabPanel>
						</Box>
					</Grid>

					<Grid item xs={3}>
						<Sidebar results={results} project={project} setRiskfactor={setRiskfactor}/>
					</Grid>
				</Grid>
			</Box>

			{/*Dialogs & Snackbars*/}
			<ConfirmDialog mRef={confirmDialog}/>
			<ScenarioDialog mRef={scenarioDialog}/>

			<Snackbar open={snackBar.open} autoHideDuration={6000} onClose={closeSnackbar}>
				<Alert
					severity={snackBar.type}
					variant="filled"
					onClose={closeSnackbar}>
					{snackBar.message}
				</Alert>
			</Snackbar>
		</>
	);
};

export default ProjectDetail;
