import {
    Box,
    Button,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import {Download, DescriptionRounded} from '@mui/icons-material';

import generateCSV from '../../../providers/generate-csv';

import Accordion from '../../../components/Accordion';

import JiraConfigFile from '../../../assets/jira-config.txt';

const ResultsItem = ({data, subTicket = false, riskfactor = 1}) => {
    return data.hidden ? null : (
        <>
            <TableRow sx={{'&:last-child > *': {borderBottom: 'unset'}}}>
                <TableCell>
                    <Box display="flex" gap={1} alignItems="center">
                        <span>{subTicket ? '— ' : ''}{data.summary}</span>
                        {data.description && (
                            <Tooltip title={
                                <Typography variant="caption">
                                    {data.description.trim().split('\n').map((l, x) => <span key={x}>{l}<br /></span>)}
                                </Typography>
                            }>
                                <DescriptionRounded color="action" sx={{width: 16, height: 16}}/>
                            </Tooltip>
                        )}
                    </Box>
                </TableCell>
                <TableCell>{data.assignee?.label ?? ''}</TableCell>
                <TableCell>{data.duration ?? ''}</TableCell>
                <TableCell>{data.estimate ? data.estimate * riskfactor : ''}</TableCell>
                <TableCell>{data.startDate?.format('MMM DD, YYYY') ?? ''}</TableCell>
                <TableCell>{data.endDate?.format('MMM DD, YYYY') ?? ''}</TableCell>
            </TableRow>

            {data.subtickets && data.subtickets.filter(({hidden}) => !hidden).map((ticket, ticketKey) => (
                <ResultsItem key={ticketKey} data={ticket} subTicket/>
            ))}
        </>
    );
};

const Results = ({results, riskfactor = 1}) => {
    const downloadCSV = () => {
        generateCSV(results.tickets, riskfactor);
    };

    return (
        <Stack spacing={4}>
            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button
                    variant="outlined"
                    sx={{marginRight: 2}}
                    href={JiraConfigFile}
                    download>
                    Get Config File
                </Button>

                <Button
                    variant="contained"
                    endIcon={<Download/>}
                    onClick={downloadCSV}>
                    Export CSV
                </Button>
            </Box>

            <Box>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2}}>
                    <Typography variant="h6">Production Summary</Typography>
                </Box>

                <TableContainer component={Paper} elevation={2}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Phase</TableCell>
                                <TableCell width={70}>Days</TableCell>
                                <TableCell width={70}>Hours</TableCell>
                                <TableCell width={70}>Cost</TableCell>
                                <TableCell width={100}>Start Date</TableCell>
                                <TableCell width={100}>End Date</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {results.projectSummary.phases.map(phase => (
                                <TableRow key={phase.title} sx={{'&:not(:nth-last-child(2)) > *': {borderBottom: 'unset'}}}>
                                    <TableCell>{phase.title}</TableCell>
                                    <TableCell>{phase.duration ?? ''}</TableCell>
                                    <TableCell>{phase.estimate ? phase.estimate * riskfactor : ''}</TableCell>
                                    <TableCell>{phase.cost ?? ''}</TableCell>
                                    <TableCell>{phase.startDate?.format('MMM DD, YYYY') ?? ''}</TableCell>
                                    <TableCell>{phase.endDate?.format('MMM DD, YYYY') ?? ''}</TableCell>
                                </TableRow>
                            ))}

                            <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
                                <TableCell>Total</TableCell>
                                <TableCell>{results.projectSummary.totals.production.duration}</TableCell>
                                <TableCell>{results.projectSummary.totals.production.estimate * riskfactor}</TableCell>
                                <TableCell>{results.projectSummary.totals.production.cost}</TableCell>
                                <TableCell>{results.projectSummary.totals.sum.startDate?.format('MMM DD, YYYY') ?? ''}</TableCell>
                                <TableCell>{results.projectSummary.totals.sum.endDate?.format('MMM DD, YYYY') ?? ''}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            <Box>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2}}>
                    <Typography variant="h6">Totals</Typography>
                </Box>

                <TableContainer component={Paper} elevation={2}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Area</TableCell>
                                <TableCell width={70}>Days</TableCell>
                                <TableCell width={70}>Hours</TableCell>
                                <TableCell width={70}>Cost</TableCell>
                                <TableCell width={100}>Start Date</TableCell>
                                <TableCell width={100}>End Date</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
                                <TableCell>Production</TableCell>
                                <TableCell>{results.projectSummary.totals.production.duration}</TableCell>
                                <TableCell>{results.projectSummary.totals.production.estimate* riskfactor}</TableCell>
                                <TableCell>{results.projectSummary.totals.production.cost}</TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>-</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>Management</TableCell>
                                <TableCell>{results.projectSummary.totals.management.duration}</TableCell>
                                <TableCell>{results.projectSummary.totals.management.estimate * riskfactor}</TableCell>
                                <TableCell>{results.projectSummary.totals.management.cost}</TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>-</TableCell>
                            </TableRow>

                            <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
                                <TableCell>Total</TableCell>
                                <TableCell>{results.projectSummary.totals.sum.duration}</TableCell>
                                <TableCell>{results.projectSummary.totals.sum.estimate * riskfactor}</TableCell>
                                <TableCell>{results.projectSummary.totals.sum.cost}</TableCell>
                                <TableCell>{results.projectSummary.totals.sum.startDate?.format('MMM DD, YYYY') ?? ''}</TableCell>
                                <TableCell>{results.projectSummary.totals.sum.endDate?.format('MMM DD, YYYY') ?? ''}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            <Stack spacing={2}>
                <Typography variant="h6">Phases</Typography>

                <Stack spacing={2}>
                    {results.tickets.map((epic, epicKey) => (
                        <Box key={epicKey}>
                            <Accordion elevation={2} disablePadding title={(
                                <span className="result-epic-header">
                                    <span>{epic.summary}</span>
                                    <span className="result-epic-duration">
                                        {epic.startDate.format('MMM DD, YYYY')} - {epic.endDate.format('MMM DD, YYYY')} ({epic.duration} days)
                                    </span>
                                </span>
                            )}>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Title</TableCell>
                                                <TableCell width={100}>Assignee</TableCell>
                                                <TableCell width={50}>Days</TableCell>
                                                <TableCell width={50}>Hours</TableCell>
                                                <TableCell width={100}>Start Date</TableCell>
                                                <TableCell width={100}>End Date</TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {epic.subtickets.map((ticket, ticketKey) => (
                                                <ResultsItem key={ticketKey} data={ticket} riskfactor={riskfactor}/>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Accordion>
                        </Box>
                    ))}
                </Stack>
            </Stack>
        </Stack>
    );
};

export default Results;
