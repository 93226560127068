function countLinks(tickets, current = 0) {
    for (const ticket of tickets) {
        const length = ticket.blocks.length;

        if (length > current) current = length;

        if (ticket.subtickets.length > 0) {
            current = countLinks(ticket.subtickets, current);
        }
    }

    return current;
}

function getSkill(skill) {
    if (!skill) return [];

    const baseSkillLabels = {
        projectManager: 'Project_Manager',
        accountExecutive: 'Account_Executive',
        uxStrategist: 'UX_Strategist',
        seniorDesigner: 'Senior_Designer',
        juniorDesigner: 'Junior_Designer',
        seniorDeveloper: 'Senior_Developer',
        juniorDeveloper: 'Junior_Developer',
    };

    return `skill#${baseSkillLabels[skill]}`;
}

function getEpicColor(epicName) {
    const colors = {
        blue: 10,
        cyan: 11,
        gray: 12,
        green: 6,
        purple: 7,
        red: 9,
        yellow: 3,
        darkBlue: 4,
        darkCyan: 5,
        darkGray: 1,
        darkGreen: 13,
        darkPurple: 8,
        darkRed: 14,
        darkYellow: 2,
    };

    const epicColors = {
        darkGray: ['Setup'],
        gray: ['Strategy', 'Content Collection'],
        darkBlue: ['Moodboards', 'Wires', 'Initial Design'],
        blue: ['Inner Design - Desktop', 'Inner Design - Mobile'],
        green: ['Inner Design - Approvals', 'Launch'],
        darkPurple: ['Initial Development'],
        purple: ['Inner Development'],
        yellow: ['UAT'],
    };

    for (const color in epicColors) {
        if (epicColors[color].includes(epicName)) return `ghx-label-${colors[color]}`;
    }

    return `ghx-label-${colors['gray']}`;
}

export default function generateCSV(epics, riskfactor) {
    const linkCount = countLinks(epics);

    const fileRows = [
        [
            'ID',
            'Type',
            'Summary',
            'Description',
            'Assignee',
            'Epic Name',
            'Epic Color',
            'Epic Link',
            'Parent Link',
            'Start Date',
            'End Date',
            'Estimate',
            'Skill',
        ],
    ];

    for (let x = 0; x < linkCount; x++) {
        fileRows[0].push(`Blocks ${x + 1}`);
    }

    // Add tickets
    function addRows({tickets, parent = false}) {
        for (const ticket of tickets) {
            let ID,
                type,
                summary,
                description,
                assignee,
                epicName,
                epicColor,
                epicLink,
                parentLink,
                startDate,
                endDate,
                estimate,
                skill,
                blocks;

            // * Generic Info
            ID = ticket.ID;
            type = ticket.type;
            summary = ticket.summary;
            description = ticket.description ? ticket.description.trim() : '';
            // assignee = 'kelvin'
            assignee = ticket.assignee ? ticket.assignee.value : '';

            // * Epic Name
            epicName = type === 'Epic' ? summary : '';

            // * Epic Color
            epicColor = type === 'Epic' ? getEpicColor(summary) : '';

            // * Epic Link
            if (type === 'Epic' || (!parent || parent.type !== 'Epic')) {
                epicLink = '';
            } else {
                epicLink = parent.summary;
            }

            // * Parent Link (subtasks)
            if (parent && parent.type !== 'Epic') {
                parentLink = parent.ID;
            } else {
                parentLink = '';
            }

            // * Start and End Dates
            startDate = typeof ticket.startDate !== 'undefined' ? ticket.startDate.format('DD/MM/YYYY') : '';
            endDate = typeof ticket.endDate !== 'undefined' ? ticket.endDate.format('DD/MM/YYYY') : '';

            // * Estimate
            estimate = ticket.estimate ? ( ticket.estimate * 60 * 60 ) * riskfactor : '';

            // * Skill Labels
            skill = getSkill(ticket.skill);

            // * Block Links
            blocks = [];

            for (let x = 0; x < linkCount; x++) {
                blocks.push(ticket.blocks[x] ?? '');
            }

            // Add row
            fileRows.push([
                ID,
                type,
                summary,
                description,
                assignee,
                epicName,
                epicColor,
                epicLink,
                parentLink,
                startDate,
                endDate,
                estimate,
                skill,
                ...blocks,
            ]);

            // Add subtickets
            if (ticket.subtickets.length > 0) {
                addRows({tickets: ticket.subtickets, parent: ticket});
            }
        }
    }

    addRows({tickets: epics});

    // Generate file content
    const fileContents = fileRows.map(row =>
        row
            .map(String)
            .map(v => v.replaceAll('"', '""'))
            .map(v => `"${v}"`)
            .join(','),
    ).join('\r\n');

    // Create a blob
    const blob = new Blob([fileContents], {type: 'text/csv;charset=utf-8;'});
    const url = URL.createObjectURL(blob);

    // Create a link to download it
    const pom = document.createElement('a');
    pom.href = url;
    pom.setAttribute('download', 'jira-import.csv');
    pom.click();
}
