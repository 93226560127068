import {useCallback} from "react";

import {debounce} from "@mui/material/utils";
import {Alert, Box, Grid, MenuItem, Paper, Select, Slider, Stack, TextField, Typography} from '@mui/material';

import Accordion from '../../components/Accordion';
import DataControl from '../../components/DataControl';

import statusList from '../../data/project-status-list';



const Sidebar = ({results, project, setRiskfactor}) => {
	const display = Object.keys(results).length > 0 ? [
		['Kickoff', results.kickoff?.format('MMM DD, YYYY') ?? '', 6],
		['Launch', results.launch?.format('MMM DD, YYYY') ?? '', 6],
		['Total Hours', results.totalHours, 6],
		['Duration', `${results.duration} months`, 6],
		['Budget', results.budget, 12],
		['Pages', results.content.pages + 1, 4],
		['Features', results.content.features, 4],
		['Integrations', results.content.integrations, 4],
	] : [];

	const marks = [
		{
			value: 1,
			label: 'No Risk',
		},
		{
			value: 2,
			label: 'Medium Risk',
		},
		{
			value: 3,
			label: 'High Risk',
		}
    ];

	const riskFactors = project.getRiskFactorsList();

	const handleSliderChange = useCallback((event, value, activeThumb) => {
		debounceSliderChange(value);
	}, []);

	const debounceSliderChange = debounce((val) => {
		let value = riskFactors[val-1].value;
		setRiskfactor(value);
	}, 200);

	return (
		<Stack spacing={2}>
			<Accordion title="Project Info" addBox>
				<Stack spacing={3}>
					<DataControl
						component={TextField}
						label="Project Title"
						name="title"
						controller={project.data}
						permissionType="edit_details"
						required
					/>

					{project.data.get('archived') ? (
						<TextField label="Project Status" value="Archived" disabled/>
					) : (
						<DataControl
							component={Select}
							label="Project Status"
							name="status"
							controller={project.data}
							permissionType="edit_details"
							required>
							{statusList.map(status => (
								<MenuItem key={status} value={status}>{status}</MenuItem>
							))}
						</DataControl>
					)}
				</Stack>
			</Accordion>

			<Accordion title="Summary" detailsSx={{pt: 0}} addBox>
				<Alert
					severity="info"
					sx={{mb: 2}}>
					Scenario: <strong>{project.scenario.get('title')}</strong>
				</Alert>

				<Box>
					<Grid container spacing={1}>
						{display.map((res, resKey) => (
							<Grid item key={resKey} xs={res[2]}>
								<Box sx={{px: 1.5, pt: 1.5, pb: 1}} component={Paper} elevation={2}>
									<Typography variant="subtitle2"
												fontSize={12}>{res[0]}</Typography>
									<Typography variant="h6" color="primary"
												fontSize={16}>{res[1]}</Typography>
								</Box>
							</Grid>
						))}
					</Grid>
				</Box>
			</Accordion>

			<Accordion title="Risk Factor" addBox>
				<Stack spacing={3}>
					<Slider
                        min={1}
                        max={3}
                        step={1}
                        label="Risk"
                        marks={marks}
						valueLabelFormat={(value) => {
							return riskFactors[value-1].value;
						}}
                        valueLabelDisplay="auto"
						onChange={(e, v, a) => handleSliderChange(e, v, a)}
                        />
				</Stack>
			</Accordion>
		</Stack>
	);
};

export default Sidebar;
